<template>
    <div class="card" v-if="attributesList.length > 0">
        <div class="card-body">
            <div class="form-group mb-0">
                <label v-text="$t('general.attributes')"></label>
            </div>
            <div class="row">
                <div class="col-6" v-for=" (attribute,index) in attributesList " :key="index">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group form-check mb-1">
                                <input type="checkbox" v-model="attribute.value" class="form-check-input"
                                    :id="attribute.id">
                                <label :for="attribute.id" v-text="$t('attributes.' + attribute.name) " class="form-check-label"></label>
                            </div>
                        </div>
                        <div class="col-12 pl-5"
                            v-if="attribute.value && attribute.values && (attribute.values.length > 0 )">
                            <div class="row" v-for=" (attributeValue, attrValIndex) in attribute.values  "
                                :key="attrValIndex + 'attrVal' ">
                                <div class="col-12">
                                    <div class="form-group form-check mb-0">
                                        <input type="radio" class="form-check-input" v-model="attribute.attribSelected"
                                            :value="attributeValue.id" :id="attribute.id + attributeValue.name ">
                                        <label :for="attribute.id + attributeValue.name"
                                            class="form-check-label text-capitalize"
                                            v-text="$t('attributes.values.' + attributeValue.name)"></label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <multi-select class="mb-1 ml-4" btnClass="w-100 text-left"
                                        v-model="attributeValue.optionsSelected" :btnLabel="btnLabel"
                                        :options="multiSelectOptions" :selectOptions="attributeValue.options"
                                        :clear-on-select="false"
                                        v-if=" (attributeValue.id == attribute.attribSelected)  && ( attributeValue.options && attributeValue.options.length > 0 )">
                                        <template v-slot:option="{option}">
                                            <div class="form-group form-check mb-0">
                                                <input type="checkbox" :checked="option.selected"
                                                    class="form-check-input" />
                                                <span class="form-check-label">{{option.name}}</span>
                                            </div>
                                        </template>
                                    </multi-select>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <pre> {{attributesList}} </pre> -->
        </div>
    </div>

</template>

<script>
    import MultiSelect from 'vue-multi-select';
    import 'vue-multi-select/dist/lib/vue-multi-select.css';
    import {
        mapState,
        mapMutations,
        mapActions
    } from 'vuex';
    export default {
        components: {
            MultiSelect,
        },
        data() {
            return {
                multiSelectOptions: {
                    multi: true,
                    cssSelected: option => (option.selected ? {
                        'background-color': '#81b23a'
                    } : ''),
                },
                btnLabel: values => this.$t('messages.miltiSelect') + ` (${values.length})`,
            }
        },
        computed: {
            ...mapState('EcartProduct', ['attributesList']),
        },
        methods: {
            fnValidateAttributes() {
                let tempAttribsList = [];
                this.attributesList.map(attrib => {
                    if (attrib.value) {
                        let tempAttrib = {
                            attribute_id: attrib.id,
                            values: []
                        };
                        let valueSelected = attrib.values.find(value => value.id == attrib.attribSelected);
                        if (valueSelected) {
                            if (valueSelected.options) {
                                if (valueSelected.optionsSelected.length > 0) {
                                    valueSelected.optionsSelected.map(optSelected => {
                                        tempAttrib.values.push({
                                            id: valueSelected.id,
                                            value: optSelected.name
                                        });
                                    });
                                }
                            } else {
                                tempAttrib.values.push({
                                    id: valueSelected.id,
                                    value: valueSelected.name
                                });
                            }
                        }

                        if (tempAttrib.values.length > 0) {
                            tempAttribsList.push(tempAttrib);
                        }
                    }
                });
                if (tempAttribsList.length > 0) {
                    // this.fnApiUpdateAttributes(tempAttribsList);
                }
            },
        }
    }
</script>